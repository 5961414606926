import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const h3Style = props => css`
  font-size: ${props.theme.fontSize.m};
  color: ${props.theme.color.gray1};
  font-weight: ${props.theme.fontWeight.semiBold};
  line-height: 1.5;
  letter-spacing: normal;
  margin: 0;
`

export const H3 = styled.h3`
  ${h3Style}
`
