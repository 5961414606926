import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const h2Style = props => css`
  font-size: ${props.theme.fontSize.l};
  color: ${props.theme.color.gray1};
  font-weight: ${props.theme.fontWeight.bold};
  line-height: 1.54;
  letter-spacing: normal;
  margin: 0;
`

export const H2 = styled.h2`
  ${h2Style}
`
