import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const h5Style = props => css`
  font-size: ${props.theme.fontSize.xs};
  color: ${props.theme.color.gray1};
  font-weight: ${props.theme.fontWeight.bold};
  line-height: 1.65;
  letter-spacing: normal;
  margin: 0;
`

export const H5 = styled.h5`
  ${h5Style}
`
