import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const h4Style = props => css`
  font-size: ${props.theme.fontSize.x};
  color: ${props.theme.color.gray1};
  font-weight: ${props.theme.fontWeight.bold};
  line-height: 1.65;
  letter-spacing: normal;
  margin: 0;
`

export const h4SemiBoldStyle = props => css`
  font-size: ${props.theme.fontSize.x};
  color: ${props.theme.color.gray1};
  font-weight: ${props.theme.fontWeight.semiBold};
  line-height: 1.65;
  letter-spacing: normal;
  margin: 0;
`

export const H4 = styled.h4`
  ${h4Style}
`
