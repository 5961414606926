import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const smallTextStyle = props => css`
  font-size: ${props.theme.fontSize.xxs};
  color: ${props.theme.color.gray1};
  font-weight: ${props.theme.fontWeight.normal};
  line-height: 1.79;
  letter-spacing: normal;
  margin: 0;
`

export const SmallText = styled.small`
  ${smallTextStyle}
`
