import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const h1Style = props => css`
  font-size: ${props.theme.fontSize.xxl};
  color: ${props.theme.color.gray1};
  font-weight: ${props.theme.fontWeight.extraBold};
  line-height: 0.7;
  letter-spacing: normal;
  margin: 0;
`

export const H1 = styled.h1`
  ${h1Style}
`
