import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const h1mStyle = props => css`
  font-size: ${props.theme.fontSize.l};
  color: ${props.theme.color.gray1};
  font-weight: ${props.theme.fontWeight.extraBold};
  line-height: 1.05;
  letter-spacing: normal;
  margin: 0;
`

export const H1M = styled.h1`
  ${h1mStyle}
`
